import { defineStore } from 'pinia'
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";
import {enumeration} from "../../Enumeration";
import {UtilisateurStores} from "@/stores/UtilisateurStores";

export const ProduitStores = defineStore("ProduitStores", {
    state: () => ({
    }),
    actions: {
        getStl: (uuidProduit, onSuccess) => {
            const utilisateurStore = UtilisateurStores();
            const notificationStore = NotificationStores();

            let url = `/produits/${uuidProduit}/stl`;

            if(utilisateurStore.role === enumeration.role.ADMIN) {
                url += "/admin";
            }

            xhr.addRequestToQueue("GET", url, undefined, true, true, false,
                (response) => {
                    if(response.code !== 200) {

                        if(response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringGetOrder"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        onSuccess(response);
                    }
                });
        }
    },
    getters : {}
});
