<template>
  <div class="w-[90%] md:w-1/3 lg:w-1/5 flex flex-col justify-between rounded-lg shadow-md p-8">
    <span class="mb-2">uuid : </span>
    <span class="mb-4">{{ props.typeFilament.uuid }}</span>
    <span class="mb-2">{{t("label")}} :</span>
    <span class="mb-4" v-show="!isEditing">{{ props.typeFilament.libelle }}</span>
    <input v-show="isEditing" class="font-normal border border-black px-2 mb-4" v-model="editLibelle" :placeholder="t('label')" />

    <div class="mt-4 flex flex-row gap-2 justify-end">
      <font-awesome-icon icon="fas fa-check" class="text-green-600 cursor-pointer" v-on:click="onEdit" v-show="isEditing" />
      <font-awesome-icon icon="fas fa-edit" class="text-orange-600 cursor-pointer" v-on:click="() => {isEditing = true;}" v-show="!isEditing"/>
      <font-awesome-icon icon="fas fa-trash" class="text-red-600 cursor-pointer" v-on:click="onDelete" />
    </div>
  </div>
</template>
uuid
<script setup>

import {t} from "../utils/Traduction";
import {defineProps, ref} from 'vue';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {NotificationStores} from "@/stores/NotificationStores";
import {TypeFilamentStore} from "@/stores/TypeFilamentStore";

const props = defineProps({
  typeFilament: Object,
  showModalDelete: Function
});

const notificationStores = NotificationStores();
const typeFilamentStore = TypeFilamentStore();

const isEditing = ref(false);
const editLibelle = ref(props.typeFilament.libelle);


const onDelete = () => {
  props.showModalDelete(props.typeFilament.uuid);
}

const onEdit = () => {
  if(editLibelle.value === "") {
    notificationStores.addNotification(t("libelleCannotBeEmpty"), 'error');
    return;
  }

  typeFilamentStore.editTypeFilament(editLibelle.value, props.typeFilament.uuid);
  isEditing.value = false;
}

</script>

<style scoped>

</style>