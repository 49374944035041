import { defineStore } from 'pinia'
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";

export const TypeFilamentStore = defineStore("TypeFilamentStore", {
    state: () => ({
        typeFilaments: [],
        showModalDelete: false,
    }),
    actions: {
        async loadTypeFilaments() {
            if(this.typeFilaments.length > 0) {
                return;
            }

            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", "/typefilaments", undefined, true, true, false,
                (response) => {
                    if (response.code !== 200) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringLoadFilamentType"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        this.typeFilaments = JSON.parse(response.response);
                    }
                });
        },

        async deleteTypeFilament(uuid) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("DELETE", "/typefilaments/" + uuid, undefined, true, true, false,
                (response) => {
                    if(response.code !== 204) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringDeleteFilamentType"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        notificationStore.addNotification(t("typeFilamentDeleted"), 'success');

                        this.typeFilaments = this.typeFilaments.filter(typeFilament => typeFilament.uuid !== uuid);
                    }
                });
        },

        async editTypeFilament(libelle, uuid) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("PUT", "/typefilaments/" + uuid, { libelle }, true, true, false,
                (response) => {
                    if(response.code !== 200) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringEditingTypeFilament"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        const typeFilament = this.typeFilaments.find((typeFilament) => typeFilament.uuid === uuid);
                        typeFilament.libelle = libelle;

                        notificationStore.addNotification(t("typeFilamentEdited"), 'success');
                    }
                });
        },

        async createTypeFilament(libelle) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("POST", "/typefilaments", { libelle }, true, true, false,
                (response) => {
                    if(response.code !== 201) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringCreateTypeFilament"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        this.typeFilaments.push(JSON.parse(response.response));

                        notificationStore.addNotification(t("typeFilamentCreated"), 'success');
                    }
                });
        }
    },
    getters : {}
});
