import { defineStore } from 'pinia'
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";

export const CouleurStore = defineStore("CouleurStore", {
    state: () => ({
        couleurs: [],
        showModalCreation: false,
    }),
    actions: {
        async loadCouleurs() {
            if(this.couleurs.length > 0) {
                return;
            }

            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", "/couleurs", undefined, true, true, false,
            (response) => {
                if (response.code !== 200) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringGetColors"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    this.couleurs = JSON.parse(response.response);
                }
            });
        },
        async deleteCouleur(uuid) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("DELETE", "/couleurs/" + uuid, undefined, true, true, false,
            (response) => {
                if (response.code !== 204) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringDeleteColors"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    this.couleurs = this.couleurs.filter(couleur => couleur.uuid !== uuid);
                    notificationStore.addNotification(
                        t("colorDeleted"),
                        "success");
                }
            });
        },
        async editCouleur(uuid, libelle, code) {

            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("PUT", "/couleurs/" + uuid, {libelle: libelle, code: code}, true, true, false,
            (response) => {
                if (response.code !== 200) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringEditColor"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    const index = this.couleurs.findIndex(couleur => couleur.uuid === uuid);
                    this.couleurs[index].libelle = libelle;
                    this.couleurs[index].code = code;
                    notificationStore.addNotification(
                        t("colorEdited"),
                        "success");
                }
            });
        }
    },
    getters : {}
});
