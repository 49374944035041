<template>
  <div class="z-0 flex flex-col items-center justify-center w-4/5 md:w-1/3 mt-4 rounded-lg shadow-md p-4 mx-4 relative">
    <span class="mb-2" v-show="!isEditing">{{props.couleur.libelle}}</span>
    <div :style="{ backgroundColor: props.couleur.code }"
    class="h-4 w-4/5 border border-black" v-show="!isEditing" v-if="props.couleur.code"></div>

    <input v-model="libelleValeur" v-show="isEditing" class="w-4/5 h-6 text-center" />
    <input type="color" v-model="codeValeur" v-show="isEditing" class="w-4/5 h-6" />

    <div class="absolute top-2 right-2 flex flex-col">
      <font-awesome-icon icon="fas fa-edit" v-show="!isEditing" v-on:click="isEditing = true" class="cursor-pointer mb-2 text-lg text-orange-500" />
      <font-awesome-icon icon="fas fa-check" v-show="isEditing" v-on:click="editCouleur" class="cursor-pointer mb-2 text-lg text-green-600" />
      <font-awesome-icon icon="fas fa-trash" v-on:click="showDeleteModal = true" class="cursor-pointer text-lg text-red-600" />
    </div>
  </div>

  <modalSuppressionCouleurComponent class="z-10" v-if="showDeleteModal" :closeModal="closeModal" :couleur="props.couleur.libelle" />
</template>

<script setup>

import {defineProps, ref} from 'vue';
import ModalSuppressionCouleurComponent from "@/components/ModalSuppressionCouleurComponent.vue";
import {CouleurStore} from "@/stores/CouleurStore";
import {NotificationStores} from "@/stores/NotificationStores";
import {t} from "@/utils/Traduction";

const couleurStore = CouleurStore();
const notificationStores = NotificationStores();

const props = defineProps({
    couleur: Object
});

const isEditing = ref(false);
const showDeleteModal = ref(false);
const libelleValeur = ref(props.couleur.libelle);
const codeValeur = ref(props.couleur.code);

const closeModal = (hasDelete) => {
    showDeleteModal.value = false;
    if(hasDelete) {
        couleurStore.deleteCouleur(props.couleur.uuid);
    }
}

const editCouleur = () => {
  if(libelleValeur.value.trim() === "") {
    notificationStores.addNotification(t('labelCannotBeEmpty'), 'error');
    return;
  }

  couleurStore.editCouleur(props.couleur.uuid, libelleValeur.value, codeValeur.value);
  isEditing.value = false;
}

</script>

<style scoped>

</style>