<template>
  <div class="w-[90%] md:w-1/3 lg:w-1/5 flex flex-col justify-between rounded-lg shadow-md p-8">
    <span class="mb-2">uuid : </span>
    <span class="mb-4">{{ props.marque.uuid }}</span>
    <span class="mb-2">{{t("brandName")}} :</span>
    <span class="mb-4" v-show="!isEditing">{{ props.marque.nom }}</span>
    <input v-show="isEditing" class="font-normal border border-black px-2 mb-4" v-model="editName" :placeholder="t('brandName')" />

    <div class="mt-4 flex flex-row gap-2 justify-end">
      <font-awesome-icon icon="fas fa-check" class="text-green-600 cursor-pointer" v-on:click="onEdit" v-show="isEditing" />
      <font-awesome-icon icon="fas fa-edit" class="text-orange-600 cursor-pointer" v-on:click="() => {isEditing = true;}" v-show="!isEditing"/>
      <font-awesome-icon icon="fas fa-trash" class="text-red-600 cursor-pointer" v-on:click="onDelete" />
    </div>
  </div>
</template>

<script setup>

import {t} from "../utils/Traduction";
import {defineProps, ref} from 'vue';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {NotificationStores} from "@/stores/NotificationStores";
import {MarqueStore} from "@/stores/MarqueStore";

const props = defineProps({
  marque: Object,
  showModalDelete: Function
});

const notificationStores = NotificationStores();
const marqueStore = MarqueStore();

const isEditing = ref(false);
const editName = ref(props.marque.nom);


const onDelete = () => {
  props.showModalDelete(props.marque.uuid);
}

const onEdit = () => {
  if(editName.value === "") {
    notificationStores.addNotification(t("messageCannotBeEmpty"), 'error');
    return;
  }

  marqueStore.editMarque(editName.value, props.marque.uuid);
  isEditing.value = false;
}

</script>

<style scoped>

</style>