<template>
  <div class="w-full md:w-1/3 lg:w-1/4 mx-4 lg:mx-0 rounded bg-white shadow-xl justify-center flex flex-col items-center p-8">
    <img :src="props.imprimante.urlImage" class="w-3/4">
    <div class="w-full flex justify-between">
      <div class="flex flex-col" v-show="!isEditing">
        <span class="text-3xl">{{ imprimante.modele }}</span>
        <span class="text-xl">{{ imprimante.marque }}</span>
        <span class="text-1xl mt-2">{{ imprimante.consommation }} Watts</span>
        <span class="text-1xl mt-2">{{ imprimante.prix }} €</span>
      </div>

      <div class="flex flex-col gap-4 mr-2 w-full" v-show="isEditing">
        <label for="marque" class="w-full">
          <input id="marque" :placeholder="t('brand')" class="w-full border border-black rounded"
                 v-model="newMarque">
        </label>

        <label for="model" class="w-full">
          <input id="model" :placeholder="t('model')" class="w-full border border-black rounded"
                 v-model="newModel">
        </label>

        <label for="consommation" class="w-full">
          <input type="number" id="consommation" :placeholder="t('consommation')" class="w-full border border-black rounded"
                 v-model="newConsommation">
        </label>

        <label for="prix" class="w-full">
          <input type="number" id="prix" :placeholder="t('price')" class="w-full border border-black rounded"
                 v-model="newPrice">
        </label>

        <label for="urlImage" class="w-full">
          <input id="urlImage" :placeholder="t('urlImgPrinter')" class="w-full border border-black rounded"
                 v-model="newUrlImage">
        </label>
      </div>

      <div class="flex flex-col items-center justify-end">
        <font-awesome-icon icon="fas fa-arrow-right" @click="goToDetail()" class="cursor-pointer text-lg text-blue-500" />
        <font-awesome-icon v-if="!isEditing" icon="fas fa-edit" @click="setToEditMode()" class="cursor-pointer text-lg mt-2 text-orange-600" />
        <font-awesome-icon v-if="isEditing" icon="fas fa-check" @click="modifierImprimante()" class="cursor-pointer text-lg mt-2 text-green-600" />
        <font-awesome-icon icon="fas fa-trash" @click="props.showModalDelete(imprimante)" class="cursor-pointer text-lg mt-2 text-red-600" />
      </div>
    </div>
  </div>

</template>

<script setup>
import {defineProps, ref} from 'vue';
import {t} from "@/utils/Traduction";
import {ImprimantesStore} from "@/stores/ImprimanteStore";
import {router} from "@/routeur";

const props = defineProps({
  imprimante: Object,
  showModalDelete: Function
});

const imprimanteStore = ImprimantesStore();

const isEditing = ref(false);
const newMarque = ref(props.imprimante.marque);
const newModel = ref(props.imprimante.modele);
const newConsommation = ref(props.imprimante.consommation);
const newPrice = ref(props.imprimante.prix);
const newUrlImage = ref(props.imprimante.urlImage);

function setToEditMode() {
  isEditing.value = true;
}

const modifierImprimante = () => {
  imprimanteStore.modifierImprimante(props.imprimante.uuid, newMarque.value, newModel.value, newConsommation.value, newPrice.value, newUrlImage.value,
      () => {
    isEditing.value = false;
  });
}

const goToDetail = async () => {
  await router.push({'path': '/admin/imprimantes/stats/' + props.imprimante.uuid});
}

</script>

<style scoped>
</style>
