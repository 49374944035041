<template>
  <div class="bg-white-levitation lg:max-w-md md:w-2/3 w-full p-8 bg-white rounded shadow-xl relative mt-8">
      <font-awesome-icon icon="fas fa-times" class="absolute top-2 right-2 text-red-500 cursor-pointer text-xl" @click="deleteMessage"/>
    <div class="text-center text-2xl font-semibold mb-2">{{ props.message.prenom }} {{ props.message.nom }}</div>
    <div class="text-center text-sm text-gray-600 mb-2">{{ props.message.email }}</div>
    <div class="mb-4">
      <div class="text-center text-gray-700 text-sm font-medium mb-2">{{t("message")}}</div>
      <div class="p-3 bg-gray-100 rounded border">{{ props.message.message }}</div>
    </div>
  </div>
</template>

<script setup >

import { defineProps } from 'vue';
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import {ContactStores} from "@/stores/ContactStore";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";

const notificationStore = NotificationStores();
const contactStore = ContactStores();

const props = defineProps({
  message: Object,
});

const deleteMessage = async () => {
  xhr.addRequestToQueue("DELETE", '/contacts/' + props.message.uuid, undefined, true, true, false,
    (response) => {
      if (response.code !== 200) {
        if (response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringDeleteMessage"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t('successDeleteMessage'),
            "success");

        contactStore.removeFromUuid(props.message.uuid);
      }
    });
}

</script>

<style scoped>

</style>