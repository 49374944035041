<template>
  <div class="fixed inset-0 flex items-center justify-center z-50 mx-4 md:mx-0">
    <div class="bg-white max-w-md p-8 rounded shadow-xl md:w-2/3 lg:w-auto">
      <h2 class="text-2xl font-semibold mb-4">{{t("createAColor")}}</h2>
      <input v-model="newCouleur" type="text" :placeholder="t('colorName')" class="p-3 w-full rounded border mb-4" />
      <button @click="createCouleur" class="bg-blue-500 text-white p-2 rounded w-full">{{t("validate")}}</button>
      <button @click="closeModal" class="mt-2 p-2 text-gray-700 w-full">{{t("cancelAction")}}</button>
    </div>
  </div>
</template>

<script setup>

import {CouleurStore} from "@/stores/CouleurStore";
import {ref} from "vue";
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "../utils/Traduction";

const couleurStore = CouleurStore();
const notificationStore = NotificationStores();

const newCouleur = ref("");

const createCouleur = async () => {
  if(newCouleur.value === "") {
    notificationStore.addNotification(t('colorNameCannotBeEmpty'), "error");
    return;
  }

  xhr.addRequestToQueue("POST", "/couleurs", {libelle: newCouleur.value}, true, true, false,
    (response) => {
      if (response.code !== 201) {
        if (response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t('errorDuringCreateColor'),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(`${t("color")} ${newCouleur.value} ${t("createSucces")}`, "success");

        couleurStore.couleurs.push(JSON.parse(response.response));

        newCouleur.value = "";

        closeModal();
      }
    });
}

const closeModal = () => {
  couleurStore.showModalCreation = false;
}

</script>

<style scoped>

</style>