<template>
  <div class="loader"></div>
</template>

<script setup>

</script>

<style scoped>
.loader {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: transparent;
  border-radius: 50%;
}

.loader {
  border: 4px solid #3b82f6;
  border-left-color: transparent;
  width: 20px;
  height:20px;
}

.loader {
  border: 3px solid #3b82f6;
  border-left-color: transparent;
  width: 20px;
  height: 20px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>