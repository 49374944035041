import { defineStore } from 'pinia'
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";

export const MarqueStore = defineStore("MarqueStore", {
    state: () => ({
        marques: [],
        showModalDelete: false,
    }),
    actions: {
        async loadMarques() {
            if(this.marques.length > 0) {
                return;
            }

            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", "/marques", undefined, true, true, false,
                (response) => {
                    if (response.code !== 200) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringLoadBrand"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        this.marques = JSON.parse(response.response);
                    }
                });
        },

        async deleteMarque(uuid) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("DELETE", "/marques/" + uuid, undefined, true, true, false,
                (response) => {
                    if(response.code !== 204) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringDeleteMarque"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        notificationStore.addNotification(t("brandDeleted"), 'success');

                        this.marques = this.marques.filter(marque => marque.uuid !== uuid);
                    }
                });
        },

        async editMarque(nom, uuid) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("PUT", "/marques/" + uuid, { nom }, true, true, false,
                (response) => {
                    if(response.code !== 200) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringEditBrand"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        const marque = this.marques.find((marque) => marque.uuid === uuid);
                        marque.nom = nom;

                        notificationStore.addNotification(t("brandEdited"), 'success');
                    }
                });
        },

        async createMarque(nom) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("POST", "/marques", { nom }, true, true, false,
                (response) => {
                    if(response.code !== 201) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringCreateBrand"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        this.marques.push(JSON.parse(response.response));

                        notificationStore.addNotification(t("brandCreated"), 'success');
                    }
                });
        }
    },
    getters : {}
});
