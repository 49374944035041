<template>
  <div id="app">
    <div id="nav">
      <NavigationComponent />
    </div>
    <div id="notifications" class="absolute top-0 right-0 flex flex-col items-end justify-end">
      <NotificationComponent v-for="notification of notificationStore.notifications" :id="notification.id"
                             :message="notification.message" :type="notification.type" :key="notification"/>
    </div>

    <router-view/>
  </div>
</template>

<script setup>

import NotificationComponent from "@/components/NotificationComponent";
import {NotificationStores} from "@/stores/NotificationStores";
import NavigationComponent from "@/components/NavigationComponent";

const notificationStore = NotificationStores();

window.onload = () => {
  const nav = document.getElementById("nav");
  const notifications = document.getElementById("notifications");

  notifications.style.top = `${nav.clientHeight}px`;
}

window.onresize = () => {
  const nav = document.getElementById("nav");
  const notifications = document.getElementById("notifications");

  notifications.style.top = `${nav.clientHeight}px`;
}

</script>

<style>
:root {
  background-color: #f5f5f5;
}
</style>
