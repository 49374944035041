import { defineStore } from 'pinia'
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";

export const EtatStore = defineStore("EtatStore", {
    state: () => ({
        etats: [],
    }),
    actions: {
        async loadEtatsCommandes() {
            if(this.etats.length > 0) {
                return;
            }

            const notificationStore = NotificationStores();

                xhr.addRequestToQueue("GET", "/etats/commandes", undefined, true, true, false,
            (response) => {
                if (response.code !== 200) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringGetStates"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    this.etats = JSON.parse(response.response);
                }
            });
        }
    },
    getters : {}
});
