<template>
  <div class="fixed inset-0 flex items-center justify-center z-50 mx-4 md:mx-0">
    <div class="bg-white max-w-md p-8 rounded shadow-xl md:w-2/3 lg:w-auto">
      <h2 class="text-2xl font-semibold mb-4">Ajout d'un objet raté</h2>

      <span v-show="errorMessage !== ''" class="text-red-600">{{errorMessage}}</span>

      <div>
        <span>Poids : </span>
        <input v-model="poid" type="number" minlength="1" class="border border-black rounded-lg px-1">
      </div>
      <div>
        <span>{{t("timeWithDots")}}</span>
        <input v-on:change="resolveMathTemps" v-model="temps" minlength="1" class="border border-black rounded-lg px-1">
      </div>

      <div>
        <span>{{t("coilWithDots")}}</span>
        <select v-model="bobineUsed" class="border border-black rounded-lg">
          <option selected disabled value="-1">
            {{t("chooseACoil")}}
          </option>
          <option v-for="bobine in bobinesDeLaCouleurDuProduit" :key="bobine" :value="bobine.uuid">
            {{ bobine.nombre }} - {{ bobine.couleur.libelle }} - {{ bobine.poidsRestant }}g
          </option>
        </select>
      </div>

      <div>
        <span>{{t("printerWithDot")}}</span>
        <select v-model="printerUsed" class="border border-black rounded-lg">
          <option selected disabled value="-1">
            {{t("chooseAPrinter")}}
          </option>
          <option v-for="imprimante of imprimanteStore.imprimantes" :key="imprimante" :value="imprimante.uuid">
            {{ imprimante.marque }} {{ imprimante.modele }}
          </option>
        </select>
      </div>

      <div class="flex flex-row items-start mb-4">
        <span class="mr-2">{{t("causeWithDots")}}</span>
        <textarea v-model="cause" :placeholder="t('causeOfFailed')"></textarea>
      </div>

      <button @click="validate" class="bg-blue-500 text-white p-2 rounded w-full">{{t("validate")}}</button>
      <button @click="props.hideModal" class="mt-2 p-2 text-gray-700 w-full">{{t("cancelAction")}}</button>
    </div>
  </div>

</template>

<script setup>

import {computed, defineProps, ref} from 'vue';
import { t } from '../utils/Traduction';
import {parser} from "mathjs";
import {BobineStore} from "@/stores/BobineStore";
import {ImprimantesStore} from "@/stores/ImprimanteStore";
import {FailedStore} from "@/stores/FailedStore";

const props = defineProps({
  produit: {
    type: Object,
    required: true,
  },

  hideModal: {
    type: Function,
    required: true,
  },
});

const bobineStore = BobineStore();
const imprimanteStore = ImprimantesStore();
const failedStore = FailedStore();

const bobinesDeLaCouleurDuProduit = computed(() => {
  const filteredBobines = props.produit ? bobineStore.bobines.filter(bobine => bobine.couleur.uuid === props.produit.couleur.uuid) : [];
  // Si aucune bobine correspondante n'est trouvée, retourner toutes les bobines
  return filteredBobines.length > 0 ? filteredBobines : bobineStore.bobines;
});


const poid = ref();
const temps = ref("");
const bobineUsed = ref("-1");
const printerUsed = ref("-1");
const cause = ref("");
const errorMessage = ref("");

const resolveMathTemps = () => {
  try {
    temps.value = parser().evaluate(temps.value);
  } catch(e) {
    console.log("not good syntax");
  }
}

const validate = () => {
  if(!poid.value || poid.value <= 0) {
    errorMessage.value = t("weightInfZero");
  } else if(!temps.value || temps.value <= 0) {
    errorMessage.value = t("TimeInfZero");
  } else if(bobineUsed.value === "-1") {
    errorMessage.value = t("needFillCoil");
  } else if(printerUsed.value === "-1") {
    errorMessage.value = t("needFillPrinter");
  } else if(cause.value === '') {
    errorMessage.value = t("needFillCause");
  } else {

    failedStore.create(poid.value, temps.value, bobineUsed.value, printerUsed.value, cause.value, props.produit.uuid,
        () => {
          poid.value = null;
          temps.value = null;
          bobineUsed.value = "-1";
          printerUsed.value = "-1"
          cause.value = "";

          props.hideModal();
        });

  }
}

</script>

<style scoped>

</style>