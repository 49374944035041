import { defineStore } from 'pinia'

export const NotificationStores = defineStore("NotificationStores", {
    state: () => ({
        notifications: []
    }),
    actions: {

        removeNotification(id) {
            this.notifications = this.notifications.filter(notification => notification.id !== id);
        },

        addNotification(message, type) {
            this.notifications.push({
                id: Math.random().toString(36).substr(2, 9),
                message: message,
                type: type
            });
        }
    },
    getters : {}
});
