import { defineStore } from 'pinia'
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";

export const VersionStore = defineStore("VersionStore", {
    state: () => ({
        versions: [],
    }),
    actions: {
        formatVersion(version) {
            if(version.date === null || version.date === undefined) {
                return "";
            }

            version.date = new Date(version.date);

            const dateObj = new Date(version.date);

            const jour = dateObj.getUTCDate().toString().padStart(2, '0');
            const mois = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const annee = dateObj.getUTCFullYear();

            version.date = `${jour}-${mois}-${annee}`;

            this.formatDescription(version);
        },

        formatDescription(version) {
            version.description = version.description.replace(/\n/g, "<br>");
            version.description = version.description.replace(/-b-/g, "<strong>");
            version.description = version.description.replace(/-b\/-/g, "</strong>");
        },

        unformatDescription(description) {
            description = description.replace(/<br>/g, "\n");
            description = description.replace(/<strong>/g, "-b-");
            description = description.replace(/<\/strong>/g, "-b/-");
            return description;
        },

        async loadVersions() {
            if(this.versions.length > 0) {
                return;
            }

            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", "/versions", undefined, true, true, false,
            (response) => {
                if (response.code !== 200) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringGetColors"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    this.versions = JSON.parse(response.response);

                    this.versions.forEach((version) => {
                        this.formatVersion(version);
                    });
                }
            });
        },

        addVersion(version) {
            this.formatVersion(version);

            this.versions.unshift(version);
        },

        deleteVersion(version) {
            this.versions = this.versions.filter((v) => v.uuid !== version.uuid);
        },

        updateDescription(version, nouvelleDescription) {
            version = this.versions.find((v) => v.uuid === version.uuid);
            version.description = nouvelleDescription;

            this.formatDescription(version);
        }
    },
    getters : {}
});
