<template>
  <div class="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
    <div class="bg-white shadow-xl w-[90vw] md:w-[70vw] lg:w-[60vw] max-h-[90vh] flex flex-col justify-center items-center p-4">
      <span class="text-lg my-4">{{ t("numberObjetAvailable") }}: {{ props.nbMaxBobine - totalNbObjetAttribuer }}</span>

      <!-- Ajustement pour la disposition en mode tablette et PC -->
      <div class="flex flex-col md:flex-row flex-wrap gap-4 justify-center items-center mb-4 w-full overflow-y-auto">
        <div v-for="bobine of lesBobines" :key="bobine" class="w-[85%] md:w-1/3 lg:w-1/4">
          <div class="flex flex-row justify-between items-center">
            <span>
              {{ bobine.bobine.nombre }} - {{ bobine.bobine.couleur.libelle }} - {{ bobine.bobine.poidsRestant }}
            </span>

            <div class="flex items-center border border-blue-500 rounded-full overflow-hidden mt-2 w-32 h-10">
              <button @click="removeOne(bobine.bobine.uuid)" class="w-10 h-10 text-white bg-blue-500 hover:bg-blue-800 focus:outline-none">-</button>
              <span class="w-12 text-center bg-white text-black">{{ bobine.nbObjet }}</span>
              <button @click="addOne(bobine.bobine.uuid)" class="w-10 h-10 text-white bg-blue-500 hover:bg-blue-800 focus:outline-none">+</button>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row gap-4 my-8">
        <button @click="cancel" class="bg-blue-500 text-white p-2 rounded w-full">{{ t("cancel2") }}</button>
        <button v-show="totalNbObjetAttribuer == props.nbMaxBobine" @click="validate" class="bg-blue-500 text-white p-2 rounded w-full">{{ t("validate") }}</button>
      </div>
    </div>
  </div>
</template>



<script setup>
import {computed, defineProps, reactive} from 'vue';
import {t} from "@/utils/Traduction";

const props = defineProps({
  nbMaxBobine: {
    type: Number,
    required: true
  },
  bobinesDeLaCouleur: {
    type: Array,
    required: true
  },
  closeModal: {
    type: Function,
    required: true
  }
});

const lesBobines = reactive(
    props.bobinesDeLaCouleur.map(bobine => ({ bobine, nbObjet: 0 }))
);

const totalNbObjetAttribuer = computed(() => {
  return lesBobines.reduce((total, bobine) => total + bobine.nbObjet, 0);
});

const addOne = (uuid) => {
  const bobine = lesBobines.find(bobine => bobine.bobine.uuid === uuid);

  // on utilise != car un est string et l'autre est nombre
  if(bobine && totalNbObjetAttribuer.value != props.nbMaxBobine) {
    bobine.nbObjet += 1;
  }
}

const removeOne = (uuid) => {
  const bobine = lesBobines.find(bobine => bobine.bobine.uuid === uuid);

  if(bobine && bobine.nbObjet > 0) {
    bobine.nbObjet -= 1;
  }
}

const cancel = () => {
  props.closeModal();
}

const validate = () => {
  const selected = lesBobines.filter((bobine) => bobine.nbObjet !== 0);

  props.closeModal(selected);
}

</script>

<style scoped>
</style>
