<template>
  <div class="w-[90%] md:w-1/3 flex flex-col justify-between rounded-lg shadow-md p-8 border-b-4"
    :class="{'border-b-[#00ff00]' : !isExpired(), 'border-b-[#ff0000]': isExpired()}">
    <span class="mb-2">uuid : </span>
    <span class="mb-4">{{ props.information.uuid }}</span>
    <span class="mb-2">{{t("message")}} :</span>
    <span class="mb-4" v-show="!isEditing">{{ props.information.message }}</span>
    <textarea v-show="isEditing" class="mb-4" v-model="editMessage" :placeholder="t('informationMessage')"></textarea>
    <span class="mb-2">{{t("endDate")}} :</span>
    <span v-show="!isEditing">{{ props.information.endDate ? Converter.convertIsoToYYMMDDHHmmDate(props.information.endDate).replace("T", " ") : t("any") }}</span>
    <input v-show="isEditing" type="datetime-local" v-model="editDate">

    <div class="mt-4 flex flex-row gap-2 justify-end">
      <font-awesome-icon icon="fas fa-check" class="text-green-600 cursor-pointer" v-on:click="onEdit" v-show="isEditing" />
      <font-awesome-icon icon="fas fa-edit" class="text-orange-600 cursor-pointer" v-on:click="() => {isEditing = true;}" v-show="!isEditing"/>
      <font-awesome-icon icon="fas fa-trash" class="text-red-600 cursor-pointer" v-on:click="onDelete" />
    </div>
  </div>
</template>

<script setup>

import {t} from "../utils/Traduction";
import {defineProps, ref} from 'vue';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Converter from "@/utils/Converter";
import {InformationStore} from "@/stores/InformationStore";
import {NotificationStores} from "@/stores/NotificationStores";

const props = defineProps({
  information: Object,
  showModalDelete: Function
});

const informationStore = InformationStore();
const notificationStores = NotificationStores();

const isExpired = () => {
  return new Date(props.information.endDate) < new Date() && props.information.endDate !== null;
}

const isEditing = ref(false);
const editDate = ref(Converter.convertIsoToYYMMDDHHmmDate(props.information.endDate));
const editMessage = ref(props.information.message);


const onDelete = () => {
  props.showModalDelete(props.information.uuid);
}

const onEdit = () => {
  if(editMessage.value === "") {
    notificationStores.addNotification(t("messageCannotBeEmpty"), 'error');
    return;
  }

  if(editDate.value === "") {
    editDate.value = null;
  }

  informationStore.editInformation(editMessage.value, editDate.value, props.information.uuid);
  isEditing.value = false;
}

</script>

<style scoped>

</style>