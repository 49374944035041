<template>
  <div class="flex flex-row gap-2">
    <div class="w-3 h-3 rounded-full bg-white animate-bounce"></div>
    <div class="w-3 h-3 rounded-full bg-white animate-bounce [animation-delay:-.3s]"></div>
    <div class="w-3 h-3 rounded-full bg-white animate-bounce [animation-delay:-.5s]"></div>
  </div>
</template>

<script setup>

</script>

<style scoped>

</style>