<template>
  <div class="bg-white-levitation lg:max-w-md md:w-2/3 w-full md:p-8 p-4 bg-white rounded shadow-xl mt-8">
    <div class="flex justify-between items-center">
      <div>
        <loading-text class="text-2xl font-semibold mb-2" :texte="commande.nom" :is-loaded="isLoaded" type="h2" />
        <loading-text texte="" :is-loaded="isLoaded" type="h2" />
        <etat-chips v-if="props.commande.etat.libelle !== ''" :etat="props.commande.etat" />
        <div v-if="props.commande.utilisateur" class="text-sm font-medium mt-3">
          <loading-text :texte="t('orderBy') + props.commande.utilisateur.email" :is-loaded="isLoaded"
            :type="utilisateurStore.role === enumeration.role.ADMIN ? 'router-link' : 'span'"
            :extra="props.commande.utilisateur.uuid"/>
        </div>
        <div v-if="props.commande.utilisateur" class="text-gray-700 text-sm font-medium mb-2">
          <loading-text :texte="t('delivryAddressWithDots') + props.commande.utilisateur.livraison" :is-loaded="isLoaded" type="span"/>
        </div>
        <div class="text-sm text-gray-600" :class="{'mt-3' : !props.commande.utilisateur}">
          <loading-text :texte="t('orderDate') + dateFormatee " :is-loaded="isLoaded" type="span"/>
        </div>
      </div>

      <router-link v-if="!isLoaded" :to="'/commandes/' + props.commande.uuid" class="text-blue-500">
        <font-awesome-icon icon="fas fa-arrow-right"></font-awesome-icon>
      </router-link>

      <loading-icon v-if="isLoaded" class="w-1 h-1"/>
    </div>
  </div>
</template>

<script setup>

import {defineProps, ref} from 'vue';
import EtatChips from "@/components/EtatChips";
import LoadingText from "@/components/LoadingText";
import LoadingIcon from "@/components/LoadingIcon";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration";
import {t} from "@/utils/Traduction";

const props = defineProps({
  commande: Object
});

const utilisateurStore = UtilisateurStores();

const isLoaded = ref(props.commande.uuid === undefined);

let dateFormatee = '';
if(props.commande.dateDebut) {
  const dateObj = new Date(props.commande.dateDebut);

  const jour = dateObj.getUTCDate().toString().padStart(2, '0');
  const mois = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
  const annee = dateObj.getUTCFullYear();

  dateFormatee = `${jour}-${mois}-${annee}`;
}


</script>

<style scoped>

</style>