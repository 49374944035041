<template>
  <InformationsBar v-show="information.show === true" v-for="information in informationStore.activeInformations" :key="information.uuid" :information="information" />

  <span v-if="commandeStores.commandes.length === 0 && !commandeStores.commandesLoading" class="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] text-3xl text-center">
    {{t("youDontHaveOrder")}}
  </span>


  <div class="flex flex-row justify-center mt-8">
    <span class="" v-if="utilisateurStores.role === enumeration.role.CLIENT" v-show="commandeStores.unpayedAmount > 0">
      {{t("leftToPay")}} {{commandeStores.unpayedAmount}}€
    </span>
  </div>

  <div class="flex flex-row flex-wrap justify-evenly md:mt-4 md:mb-8 mx-4 md:mx-0">
    <id-card-commande v-show="commandeStores.commandesLoading" v-for="i in 20" :key="i" :commande="{etat: {libelle: ''}}" />
    <id-card-commande v-for="commande in commandeStores.commandes" :key="commande" :commande="commande" />
  </div>

  <div class="flex flex-row justify-center">
    <button v-show="commandeStores.hasMore && commandeStores.commandes.length > 0" @click="commandeStores.getCommandeFromOffset()" class="bg-blue-500 text-white p-3 rounded-xl w-1/2 mb-8 mt-4">{{t("showMore")}}</button>
  </div>
</template>

<script setup>

import {CommandeStores} from "@/stores/CommandeStores";
import IdCardCommande from "@/components/IdCardCommande";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration";
import {t} from "../utils/Traduction";
import {InformationStore} from "@/stores/InformationStore";
import InformationsBar from "@/components/InformationsBar.vue";

const commandeStores = CommandeStores();
const utilisateurStores = UtilisateurStores();
const informationStore = InformationStore();

if(commandeStores.commandes.length === 0) {
  commandeStores.offset = 0;
  commandeStores.hasMore = true;

  commandeStores.getCommandeFromOffset();
}

if(utilisateurStores.role === enumeration.role.CLIENT) {
  commandeStores.loadUnpayed();
}

informationStore.loadActiveInformations();

</script>

<style scoped>

</style>