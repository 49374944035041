<template>
  <div class="mx-4 md:mx-32 mt-8">

    <div class="flex flex-row justify-between items-center">
      <h1 class="text-xl font-bold">{{t("version")}} {{ props.version.version }}   <span class="font-medium text-base text-gray-400">{{ version.date }}</span></h1>
      <div v-if="utilisateurStore.role === enumeration.role.ADMIN">
        <font-awesome-icon icon="fas fa-edit" class="text-orange-500 cursor-pointer mr-4" v-show="!isEditMode" v-on:click="setEditMode()"></font-awesome-icon>
        <font-awesome-icon icon="fas fa-check" class="text-blue-500 cursor-pointer mr-4" v-show="isEditMode" v-on:click="sendEdit()"></font-awesome-icon>
        <font-awesome-icon icon="fas fa-trash" class="text-red-500 cursor-pointer" v-on:click="deleteVersion(version)"></font-awesome-icon>
      </div>
    </div>

    <p class="ml-4 mt-4" v-html="version.description" v-show="!isEditMode">
    </p>

    <textarea class="w-full h-32 mt-4 pt-2 pl-2"
              v-model="editDescription"
              v-show="isEditMode"
              :placeholder="t('versionDescription')"></textarea>

    <hr class="ml-[3%] w-[94%] mt-4 h-[1px] border border-blue-600"/>
  </div>
</template>

<script setup>

import {defineProps, ref} from 'vue';
import {enumeration} from "../../Enumeration";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import {VersionStore} from "@/stores/VersionStore";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "../utils/Traduction";

const props = defineProps({
  version: Object
});

const notificationStore = new NotificationStores();
const versionStore = new VersionStore();
const utilisateurStore = new UtilisateurStores();

const isEditMode = ref(false);
const editDescription = ref(props.version.description);
editDescription.value = versionStore.unformatDescription(editDescription.value);

const deleteVersion = async (version) => {
  xhr.addRequestToQueue("DELETE", "/versions/" + version.uuid, undefined, true, true, false,
    (response) => {
      if (response.code !== 204) {
        if (response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringDeleteVersion"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t("versionDeleted"),
            "success");

        versionStore.deleteVersion(version);
      }
    });
}

const setEditMode = () => {
  isEditMode.value = true;
}

const sendEdit = async () => {

  xhr.addRequestToQueue("PUT", "/versions/" + props.version.uuid, {description: editDescription.value},
  true, true, false, (response) => {
    if (response.code !== 204) {
      if (response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
          t("errorDuringEditVersion"),
          "error");
      } else {
        notificationStore.addNotification(
          JSON.parse(response.response).message,
          "error");
      }
    } else {
      notificationStore.addNotification(
        t("versionEdited"),
        "success");
    }
  });

  isEditMode.value = false;
  versionStore.updateDescription(props.version, editDescription.value);
}

</script>


<style scoped>

</style>