<template>
  <div class="p-2 w-full sm:w-1/2 md:2/4 lg:w-1/4">
    <div class="bg-white shadow-md rounded-lg p-4 flex flex-col justify-center h-full">
      <div class="flex items-center justify-between">
        <div>
          <h2 class="text-lg font-semibold">{{t("coil")}} {{ bobine.nombre }}</h2>
          <p v-show="!isEditing"><span class="font-medium">{{t("remainingWeight")}}</span> {{ bobine.poidsRestant }} g</p>
          <span v-show="isEditing" class="font-medium">{{t("remainingWeight")}}
            <input v-model="newPoids" type="number" :placeholder="t('newCoilWeight')" class="font-normal border border-black px-2">
          </span>

          <p v-show="!isEditing"><span class="font-medium">{{ t("colorWithDots") }}</span> {{ bobine.couleur.libelle }} </p>
          <span v-show="isEditing" class="font-medium">{{t("colorWithDots")}}
            <select v-model="newCouleurUuid" class="font-normal border border-black px-2">
              <option disabled>{{t("selectAColor")}}</option>
              <option v-for="couleur of couleurStore.couleurs" :key="couleur.uuid" :value="couleur.uuid"
                      :selected="couleur.uuid === bobine.couleur.uuid">{{couleur.libelle}}</option>
            </select>
          </span>

          <p v-show="!isEditing"><span class="font-medium">{{ t("brandWithDots") }}</span> {{ bobine.marque.nom }} </p>
          <span v-show="isEditing" class="font-medium">{{t("brandWithDots")}}
            <select v-model="newMarqueUuid" class="font-normal border border-black px-2">
              <option disabled>{{t("selectABrand")}}</option>
              <option v-for="marque of marqueStore.marques" :key="marque.uuid" :value="marque.uuid"
                      :selected="marque.uuid === bobine.marque.uuid">{{marque.nom}}</option>
            </select>
          </span>

          <p v-show="!isEditing"><span class="font-medium">{{ t("filamentWithDots") }}</span> {{ bobine.typeFilament.libelle }} </p>
          <span v-show="isEditing" class="font-medium">{{t("filamentWithDots")}}
            <select v-model="newTypeFilamentUuid" class="font-normal border border-black px-2">
              <option disabled>{{t("selectAFilament")}}</option>
              <option v-for="type of typeFilamentStore.typeFilaments" :key="type.uuid" :value="type.uuid"
                      :selected="type.uuid === bobine.typeFilament.uuid">{{type.libelle}}</option>
            </select>
          </span>
        </div>
        <div class="flex flex-col h-full">
          <font-awesome-icon icon="fas fa-circle-exclamation"  @click="setToDefectueuse()" class="cursor-pointer text-lg mb-2"
            :class="{'text-orange-500' : !bobine.isDefectueuse, 'text-blue-600' : bobine.isDefectueuse}" />
          <font-awesome-icon v-if="!isEditing" icon="fas fa-edit" @click="setToEditMode()" class="cursor-pointer text-lg text-green-600" />
          <font-awesome-icon v-if="isEditing" icon="fas fa-check" @click="modifier()" class="cursor-pointer text-lg text-green-600" />
          <font-awesome-icon icon="fas fa-trash" @click="props.showModalDelete(bobine.uuid, bobine.poidsRestant)" class="cursor-pointer text-lg mt-2 text-red-600" />
        </div>
      </div>
      <div v-if="bobine.isDefectueuse" class="w-full flex flex-row justify-center items-center bg-orange-600 rounded-lg mt-2">
        <span class="text-white">{{t("presumedDefective")}}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { ref } from "vue";
import {BobineStore} from "@/stores/BobineStore";
import xhr from "@/utils/xhr";
import {jsonValidator} from "@/utils/JsonValidator";
import {NotificationStores} from "@/stores/NotificationStores";
import {t} from "../utils/Traduction";
import {CouleurStore} from "@/stores/CouleurStore";
import {MarqueStore} from "@/stores/MarqueStore";
import {TypeFilamentStore} from "@/stores/TypeFilamentStore";

const bobineStore = BobineStore();
const notificationStore = NotificationStores();
const couleurStore = CouleurStore();
const marqueStore = MarqueStore();
const typeFilamentStore = TypeFilamentStore();

const props = defineProps({
  bobine: {
    type: Object,
    required: true
  },
  showModalDelete: {
    type: Function,
    required: true
  },
});

const isEditing = ref(false);
const newPoids = ref(props.bobine.poidsRestant);
const newCouleurUuid = ref(props.bobine.couleur.uuid);
const newMarqueUuid = ref(props.bobine.marque.uuid);
const newTypeFilamentUuid = ref(props.bobine.typeFilament.uuid);

const setToEditMode = () => {
  isEditing.value = true;
}

const modifier = () => {
  isEditing.value = false;
  bobineStore.modifierBobine(props.bobine.uuid, newPoids.value, newCouleurUuid.value,
      newMarqueUuid.value, newTypeFilamentUuid.value);
}

const setToDefectueuse = () => {
  xhr.addRequestToQueue("PUT", `/bobines/${props.bobine.uuid}/defective`, undefined, true, true, false,
  (response) => {
    if(response.code !== 204) {
      if(response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorSetCoilDefective"),
            "error");
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
      }
    } else {
      const bobine = bobineStore.bobines.find(bobine => bobine.uuid === props.bobine.uuid)

      if(bobine.isDefectueuse) {
        bobine.isDefectueuse = false;
        notificationStore.addNotification(
            t("successSetCoilUndefective"),
            "success");

      } else {
        bobine.isDefectueuse = true;
        notificationStore.addNotification(
            t("successSetCoilDefective"),
            "success");
      }
    }
  });
}

</script>

<style scoped>

</style>