<template>
  <div class="flex flex-col justify-start items-start bg-white p-8 rounded-xl mt-4 w-full md:w-[48%] lg:w-[23%]"
  :class="{'border border-red-600 border-2': props.produit.error}">
    <label for="nom" class="block text-gray-700 text-sm font-medium">{{t("productName")}}</label>
    <input
      @change="OnSomeThingChange"
      v-model="newProduit.nom"
      type="text"
      id="nom"
      class="mt-1 px-3 py-2 block w-full rounded border"
      :placeholder="t('inputProductName')"
      required/>

    <span class="block text-gray-700 text-sm font-medium mt-2">{{t("color")}}</span>
    <select
      @change="OnSomeThingChange"
      v-model="newProduit.couleur"
      id="couleur"
      class="mt-1 px-3 py-2 block w-full rounded border">
      <option value="-1" disabled selected>{{t("selectAColor")}}</option>
      <option v-for="couleur of couleurStore.couleurs" :key="couleur" :value="couleur.uuid">{{ couleur.libelle }}</option>
    </select>
    <button @click="createCouleur" class="text-blue-500 mt-1">{{t("addColor")}}</button>

    <label for="quantite" class="block text-gray-700 text-sm font-medium mt-2">{{t("quantity")}}</label>
    <input
      @change="OnSomeThingChange"
      v-model="newProduit.quantite"
      type="number"
      id="quantite"
      class="mt-1 px-3 py-2 block w-full rounded border"
      :placeholder="t('inputQuantity')"
      required/>

    <span class="block text-gray-700 text-sm font-medium mt-2">{{t("filling")}}</span>
    <select
        @change="OnSomeThingChange"
        v-model="newProduit.remplissage"
        id="couleur"
        class="mt-1 px-3 py-2 block w-full rounded border">
      <option v-for="i in 11" :key="i-1" :value="(i-1)*10" :selected="(i-1)*10 === 20">{{ (i-1)*10 }}%</option>
    </select>

    <input
        type="file"
        @change="onFileChange"
        :id="'productStlFile' + props.produit.temporaryId"
        name="productStlFile"
        class="hidden"
        accept=".stl"
    />

    <label :for="'productStlFile' + props.produit.temporaryId" class="cursor-pointer bg-purple-500 text-white p-3 rounded w-full text-center hover:bg-purple-700 active:bg-purple-900 mt-2 whitespace-nowrap text-ellipsis overflow-x-hidden">
      <span v-show="newProduit.fileName !== ''">{{ newProduit.fileName }}</span>
      <span v-show="newProduit.fileName === ''">{{t("chooseAStl")}}</span>
    </label>

    <button v-show="props.produit.fichier !== null" @click="() => {props.show3DRenderModal(newProduit.fichier)}"
            class="bg-blue-500 text-white px-4 py-2 rounded-md w-3/5 md:w-full flex justify-center items-center mt-2">
      <span>{{t("show3D")}}</span>
    </button>

    <div class="flex flex-row w-full justify-center  mt-4">
      <button @click="removeProduit" class="bg-red-500 text-white px-2 py-1 rounded">{{t("deleteAProduct")}}</button>
    </div>
  </div>
</template>

<script setup>

import {reactive, defineProps} from "vue";
import {CouleurStore} from "@/stores/CouleurStore";
import {t} from "../utils/Traduction";

const props = defineProps({
  produit: {
    type: Object,
    required: true
  },
  removeProduitCallback: {
    type: Function,
    required: true
  },
  updateProduitCallback: {
    type: Function,
    required: true
  },
  show3DRenderModal: {
    type: Function,
    required: true
  }
});

const newProduit = reactive({...props.produit});

const couleurStore = CouleurStore();

couleurStore.loadCouleurs();

const createCouleur = () => {
  couleurStore.showModalCreation = true;
}

const removeProduit = () => {
  props.removeProduitCallback(newProduit.temporaryId);
}

const onFileChange = (event) => {
  const file = event.target.files[0]
  newProduit.fileName = file.name;

  const reader = new FileReader();
  reader.readAsDataURL(file);


  reader.onload = () => {
      newProduit.fichier = reader.result;
      OnSomeThingChange();
  };
}

const OnSomeThingChange = () => {
  newProduit.error = false;
  props.updateProduitCallback(newProduit);
}

</script>

<style scoped>

</style>