import { defineStore } from 'pinia'
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";

export const ContactStores = defineStore("ContactStores", {
    state: () => ({
        contact: []
    }),
    actions: {
        async loadContacts() {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", "/contacts", undefined, true, true, false,
            (response) => {
                if (response.code !== 200) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringGetMessage"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    this.contact = JSON.parse(response.response);
                }
            });
        },

        removeFromUuid(uuid) {
            this.contact = this.contact.filter((contact) => contact.uuid !== uuid);
        }
    },
    getters : {}
});
