<template>
  <div class="mt-8 md:mt-16 flex items-center justify-center md:mx-0 mx-4">
    <div class="bg-white-levitation w-full md:w-2/3 lg:max-w-md  space-y-8 p-8 bg-white rounded shadow-xl">
      <p class="mb-4 text-sm">{{t("noNeedFillFormFull")}}<br/>{{t("ifChangeMailOnlyMailWillChange")}}</p>
      <h2 class="text-2xl font-semibold mb-4">{{t("modifyMyInformation")}}</h2>
      <form>
        <div class="mb-4">
          <label for="email" class="block text-gray-700 text-sm font-medium">{{t("newMail")}}</label>
          <input
              v-model="email"
              type="email"
              id="email"
              name="email"
              class="mt-1 p-3 block w-full rounded border"
              :placeholder="t('newMail')"
          />
        </div>

        <div class="mb-4">
          <label for="password" class="block text-gray-700 text-sm font-medium">{{t("newPassword")}}</label>
          <input
              v-model="password"
              type="password"
              id="password"
              name="password"
              class="mt-1 p-3 block w-full rounded border"
              :placeholder="t('newPassword')"
          />
        </div>

        <div class="mb-4">
          <label for="confirmPassword" class="block text-gray-700 text-sm font-medium">{{t('confirmPassword')}}</label>
          <input
              v-model="confirmPassword"
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              class="mt-1 p-3 block w-full rounded border"
              :placeholder="t('confirmPassword')"
          />
        </div>

        <div class="mb-4">
          <label for="deliveryAddress" class="block text-gray-700 text-sm font-medium">{{t("newDelivreryAddress")}}</label>
          <input
              v-model="livraison"
              type="text"
              id="deliveryAddress"
              name="deliveryAddress"
              class="mt-1 p-3 block w-full rounded border"
              :placeholder="t('newDelivreryAddress')"
          />
        </div>

        <div class="mb-4">
        <label for="langue" class="block text-gray-700 text-sm font-medium">{{t("language")}}</label>
          <select
              v-model="langue"
              id="langue"
              name="langue"
              class="mt-1 p-3 block w-full rounded border"
              required
          >
            <option value="" disabled>{{t("newLanguage")}}</option>
            <option value="fr">{{t("french")}}</option>
            <option value="en">{{t("english")}}</option>
            <option value="hi">{{t("hindi")}}</option>
            <option value="zh">{{t("chinese")}}</option>
            <option value="es">{{t("spanish")}}</option>
            <option value="ar">{{t("arabic")}}</option>
            <option value="bn">{{t("bengali")}}</option>
            <option value="pt">{{t("portuguese")}}</option>
            <option value="ru">{{t("russian")}}</option>
            <option value="ur">{{t("urdu")}}</option>

          </select>
        </div>

        <button @click="changeUser" type="button" class="bg-blue-500 text-white p-3 rounded w-full">{{ t('editAction') }}</button>
      </form>
    </div>
  </div>
</template>

<script setup>

import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {NotificationStores} from "@/stores/NotificationStores";
import {router} from "@/routeur";
import {ref} from "vue";
import xhr from "@/utils/xhr";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "../utils/Traduction";

const utilisateurStore = UtilisateurStores();
const notificationStores = NotificationStores();

if(!utilisateurStore.isConnected) {
  notificationStores.addNotification(t("youNeedToBeConnectedToAccessThisPage"), 'error');
  router.push({ path: "/" });
}

const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const livraison = ref('');
const langue = ref('');

const changeUser = async () => {

  if(email.value === '' && password.value === '' && confirmPassword.value === '' && livraison.value === '' && langue.value === '') {
    notificationStores.addNotification(
        t("youNeedToFillOneField"),
        "error");
    return;
  }

  if(password.value !== confirmPassword.value) {
    notificationStores.addNotification(
        t("passwordNotMatch"),
        "error");
    return;
  }

  const changedData = {};

  if(email.value !== '') {
    changedData.email = email.value;
  }

  if(password.value !== '' && confirmPassword.value !== '') {
    changedData.password = password.value;
    changedData.confirmPassword = confirmPassword.value;
  }

  if(livraison.value !== '') {
    changedData.livraison = livraison.value;
  }

  if(langue.value !== '') {
    changedData.langue = langue.value;
  }

  xhr.addRequestToQueue("PUT", "/utilisateurs/update", changedData, true, true, false,
    async (response) => {
      if(response.code !== 200) {

        if(response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStores.addNotification(
              t("errorDuringServerConnexion"),
              "error");
        } else {
          notificationStores.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStores.addNotification(
            t("personalInfoEditNeedToReconnect"),
            "success");

        utilisateurStore.logout();
        await router.push({ path: "/" });
      }
    });
}

</script>

<style scoped>

</style>