<template>
  <div class="flex flex-row justify-between mx-[5%] md:mx-16 w-[90%] md:w-auto mt-8">
    <h1 v-if="utilisateurStore.role === enumeration.role.CLIENT" class="text-xl">{{t("createOrder")}}</h1>

    <div v-if="utilisateurStore.role === enumeration.role.ADMIN" class="flex flex-col md:flex-row items-center w-full">
      <h1  class="text-xl">{{t("createOrderFor")}}</h1>

      <select
          v-model="uuidClient"
          id="client"
          class="mt-1 mx-2 px-3 py-2 block rounded border w-full md:w-2/5">
        <option value="-1" disabled selected>{{t("selectAClient")}}</option>
        <option v-for="client of utilisateurStore.clients" :key="client" :value="client.uuid">{{ client.email }}</option>
      </select>
    </div>

    <div class="flex flex-row h-8 items-center">
      <font-awesome-icon @click="addProduit" icon="fas fa-square-plus" class="h-full text-xl text-green-600"/>
      <span class="ml-1 md:ml-4">{{ t("product") }}</span>
    </div>
  </div>

  <div class="flex flex-row justify-center mt-4">
    <div class="w-[90%]">
      <label for="orderName" class="block text-gray-700 text-sm font-medium">{{t('orderName')}}</label>
      <input
          v-model="nomCommande"
          type="text"
          id="orderName"
          name="orderName"
          class="mt-1 p-3 block w-full rounded border"
          :placeholder="t('inputOrderName')"
          required
      />
    </div>
  </div>

  <div class="flex flex-row justify-center">
    <div class="flex flex-row justify-evenly flex-wrap w-[90%] md:w-[95%]">
      <creation-produit-component v-for="produit of produits" :key="produit" :removeProduitCallback="removeProduit" :update-produit-callback="updateProduit"
        :produit="produit" :show3-d-render-modal="show3DRenderModal" class="w-[23%]"/>
    </div>
  </div>

  <div class="w-full flex flex-row justify-center">
    <button @click="validateCommande" class="bg-blue-500 text-white px-4 py-2 rounded-md my-4">
      <loading-dot v-show="isValidationCommande" />
      <span v-show="!isValidationCommande">{{ t("confirmOrder") }}</span>
    </button>
  </div>

  <modal-creation-couleur-component v-show="couleurStore.showModalCreation"/>
  <ModalErreur :texte="texteModalError" :close-modal="() => {isModalErrorVisible = false}" v-show="isModalErrorVisible"/>
  <Modal3DRender v-show="renderModal3D !== ''" :hide-modal="close3DModelModal" :base64-data="renderModal3D" />
</template>

<script setup>

import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import {reactive, ref} from "vue";
import CreationProduitComponent from "@/components/CreationProduitComponent";
import ModalCreationCouleurComponent from "@/components/ModalCreationCouleurComponent";
import {CouleurStore} from "@/stores/CouleurStore";
import xhr from "@/utils/xhr";
import {CommandeStores} from "@/stores/CommandeStores";
import {enumeration} from "../../Enumeration";
import ModalErreur from "@/components/ModalErreur.vue";
import LoadingDot from "@/components/LoadingDot.vue";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "../utils/Traduction";
import Modal3DRender from "@/components/Modal3DRender.vue";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const couleurStore = CouleurStore();
const commandeStore = CommandeStores();

if(!utilisateurStore.isConnected) {
  notificationStore.addNotification(t("youNeedToBeConnectedToAccessThisPage"), 'error');
  router.push({ path: "/"});
}

utilisateurStore.loadClients();

const nomCommande = ref("");
const uuidClient = ref(-1);

const texteModalError = ref("");
const isModalErrorVisible = ref(false);

const isValidationCommande = ref(false);

const renderModal3D = ref("");

const show3DRenderModal = (fichier) => {
  renderModal3D.value = fichier;
}

const close3DModelModal = () => {
  renderModal3D.value = "";
}

let produits = reactive([]);

const addProduit = () => {
  produits.push({
    temporaryId: Math.random().toString(36).substring(7),
    nom: "",
    couleur: -1,
    quantite: 1,
    fichier: null,
    fileName: "",
    error: false,
    remplissage: 20,
  });
}

addProduit();

const removeProduit = (temporaryId) => {
  if(produits.length === 1){
    notificationStore.addNotification(t("requireMinOneProduct"), 'error');
    return;
  }

  const indexASupprimer = produits.findIndex(objet => objet.temporaryId === temporaryId);

  if (indexASupprimer !== -1) {
    produits.splice(indexASupprimer, 1);
  }
}

const updateProduit = (produit) => {
  const indexAUpdate = produits.findIndex(objet => objet.temporaryId === produit.temporaryId);

  if (indexAUpdate !== -1) {
    produits[indexAUpdate] = produit;
  }
}

const showModalError = (texte) => {
  texteModalError.value = texte;
  isModalErrorVisible.value = true;
}

const validateCommande = async () => {
  if(isValidationCommande.value){
    return;
  }

  isValidationCommande.value = true;

  if(nomCommande.value === ""){
    notificationStore.addNotification(t("needFillCommandeName"), 'error');
    showModalError(t("needFillCommandeName"));
    isValidationCommande.value = false;
    return;
  }

  if(utilisateurStore.role === enumeration.role.ADMIN && uuidClient.value === -1){
    notificationStore.addNotification(t("youNeedSelectAClient"), 'error');
    showModalError(t("youNeedSelectAClient"));
    isValidationCommande.value = false;
    return;
  }

  if(produits.length === 0){
    notificationStore.addNotification(t("youNeedToAddMinOneProduct"), 'error');
    showModalError(t("youNeedToAddMinOneProduct"));
    isValidationCommande.value = false;
    return;
  }

  let allProduitAreOk = true;
  for(let produit of produits) {
    if(!produit.nom || produit.nom === "" || !produit.couleur || produit.couleur === ""
        || !produit.quantite || produit.quantite <= 0 || !produit.fichier || produit.fichier === "") {
      produit.error = true;
      allProduitAreOk = false;
    }
  }

  if(!allProduitAreOk){
    notificationStore.addNotification(t('youNeedFillAllFieldsOfProduct'), 'error');
    showModalError(t("youNeedFillAllFieldsOfProduct"));
    isValidationCommande.value = false;
    return;
  }

  const produitFormated = [];
  for(let produit of produits) {
    produitFormated.push({
      nom: produit.nom,
      uuidCouleur: produit.couleur,
      nombre: produit.quantite,
      stl: produit.fichier,
      remplissage: produit.remplissage
    });
  }

  const commandeFormated = {
    nom: nomCommande.value,
    produits: produitFormated
  };

  const url = "/commandes/create" + (uuidClient.value !== -1 ? "/" + uuidClient.value : "");

  xhr.addRequestToQueue("POST", url, commandeFormated, true, true, false, async (response) => {
    if (response.code !== 201) {
      if (response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringCreateOrder"),
            "error");

        showModalError(t("errorDuringCreateOrderServerError"));


        isValidationCommande.value = false;
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
        isValidationCommande.value = false;
        showModalError(JSON.parse(response.response).message);

      }
    } else {
      notificationStore.addNotification(
          t("orderCreated"),
          "success");
      isValidationCommande.value = false;

      const commandeResponse = JSON.parse(response.response);
      commandeStore.commandes.unshift({
        uuid: commandeResponse.uuid,
        nom: nomCommande.value,
        utilisateur: commandeResponse.utilisateur,
        uuidUtilisateur: commandeResponse.uuidUtilisateur,
        dateDebut: new Date().toISOString(),
        etat: {libelle: enumeration.etatCommande.A_FAIRE},
        produits: produits
      });

      if(utilisateurStore.role === enumeration.role.ADMIN) {
        await router.push({path: "/admin/commandes"});
      } else {
        await router.push({ path: "/commandes"});
      }
    }
  });
}

</script>

<style scoped>

</style>