const converter = {};

converter.minToDays = (min) => {
    const days = min / 1440;
    const hours = (min % 1440) / 60;
    const minutes = min % 60;

    return `${Math.floor(days)}D ${Math.floor(hours)}H ${Math.floor(minutes)}M`;
}

converter.minToHours = (min) => {
    const hours = min / 60;
    const minutes = min % 60;

    return `${Math.floor(hours)}H ${Math.floor(minutes)}M`;
}

converter.round = (number) => {
    return Math.round(number * 100) / 100;
}

converter.convertIsoToYYMMDDHHmmDate = (date) => {
    if(date === null) return null;
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    const hour = d.getHours();
    const minute = d.getMinutes();
    const result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}T${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`;
    return result.replace("T", " ");
}


export default converter;