const jsonValidator = {};

jsonValidator.validate = (json) => {
    return /^[\],:{}\s]*$/.test(
        json.replace(/\\["\\/bfnrtu]/g, '@')
        .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']')
        .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
    );
}

export { jsonValidator };