<template>
  <div class="mt-8 md:mt-16 mx-4 md:mx-0 flex items-center justify-center">
    <div class="bg-white-levitation w-full md:w-2/3 lg:max-w-md space-y-8 p-8 bg-white rounded shadow-xl">
      <h2 class="text-2xl font-semibold text-center">{{t("contact")}}</h2>

      <form @submit.prevent="send">
        <div class="mb-4 grid grid-cols-2 gap-4">
          <div>
            <label for="firstName" class="block text-gray-700 text-sm font-medium">{{t("firstname")}}</label>
            <input
                v-model="prenom"
                type="text"
                id="firstName"
                name="firstName"
                class="mt-1 p-3 block w-full rounded border"
                :placeholder="t('youtFirstname')"
                required
            />
          </div>
          <div>
            <label for="lastName" class="block text-gray-700 text-sm font-medium">{{t("lastname")}}</label>
            <input
                v-model="nom"
                type="text"
                id="lastName"
                name="lastName"
                class="mt-1 p-3 block w-full rounded border"
                :placeholder="t('yourLastname')"
                required
            />
          </div>
        </div>
        <div class="mb-4">
          <label for="email" class="block text-gray-700 text-sm font-medium">{{t("email")}}</label>
          <input
              v-model="email"
              type="email"
              id="email"
              name="email"
              class="mt-1 p-3 block w-full rounded border"
              :placeholder="t('yourEmail')"
              required
          />
        </div>
        <div class="mb-4">
          <label for="message" class="block text-gray-700 text-sm font-medium">{{t('message')}}</label>
          <textarea
              v-model="message"
              id="message"
              name="message"
              class="mt-1 p-3 block w-full rounded border"
              :placeholder="t('yourMessage')"
              required
          ></textarea>
        </div>
        <button type="submit" class="bg-blue-500 text-white p-3 rounded w-full">{{t("send")}}</button>
      </form>
    </div>
  </div>
</template>

<script setup>

import {ref} from "vue";
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "../utils/Traduction";

const notificationStore = NotificationStores();

const prenom = ref("");
const nom = ref("");
const email = ref("");
const message = ref("");

const send = async () => {

  xhr.addRequestToQueue("POST", "/contacts", {prenom: prenom.value, nom: nom.value, email: email.value, message: message.value},
  false, true, false, (response) => {
    if(response.code !== 201) {
      if(response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringSendMessage"),
            "error");
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
      }
    } else {
      notificationStore.addNotification(
          t("messageSent"),
          "success");

      prenom.value = "";
      nom.value = "";
      email.value = "";
      message.value = "";
    }
  });
}
</script>

<style scoped>

</style>