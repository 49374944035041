<template>
  <div class="bg-[#fdf1dd] flex flex-row justify-between items-center  py-2">
    <div class="flex flex-row items-center">
      <font-awesome-icon icon="fas fa-triangle-exclamation" class="text-[#975000] mx-4 text-xl" />
      <span>{{ props.information.message }}</span>
    </div>

    <font-awesome-icon icon="fas fa-times" class="text-[#9e5400] mx-4 text-xl cursor-pointer" v-on:click="closeInformation" />
  </div>
</template>

<script setup>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {defineProps} from "vue";
import {InformationStore} from "@/stores/InformationStore";

const props = defineProps({
  information: Object
});

const informationStore = InformationStore();

const closeInformation = () => {
  const informationInStore = informationStore.activeInformations.find(information => information.uuid === props.information.uuid);
  informationInStore.show = false;
}

</script>

<style scoped>

</style>