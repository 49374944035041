import { defineStore } from 'pinia'
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";
import Converter from "@/utils/Converter";

export const InformationStore = defineStore("InformationStore", {
    state: () => ({
        informations: [],
        activeInformations: [],
        showModalDelete: false,
    }),
    actions: {
        async loadActiveInformations() {
            if(this.activeInformations.length > 0) {
                return;
            }

            const notificationStore = NotificationStores();

            const date = new Date();
            const formattedDate = Converter.convertIsoToYYMMDDHHmmDate(date);

            xhr.addRequestToQueue("GET", "/informations/active/" + formattedDate, undefined, true, true, false,
            (response) => {
                if (response.code !== 200) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringActiveInformations"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    this.activeInformations = JSON.parse(response.response);

                    this.activeInformations.forEach((information) => {
                        information.show = true;
                    });
                }
            });
        },

        async loadInformations() {
            if(this.informations.length > 0) {
                return;
            }

            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", "/informations", undefined, true, true, false,
                (response) => {
                    if (response.code !== 200) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringInformations"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        this.informations = JSON.parse(response.response);
                    }
                });
        },

        async deleteInformation(uuid) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("DELETE", "/informations/" + uuid, undefined, true, true, false,
                (response) => {
                    if(response.code !== 204) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringDeleteInformation"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        notificationStore.addNotification(t("informationDeleted"), 'success');

                        this.informations = this.informations.filter(information => information.uuid !== uuid);
                        this.activeInformations = this.activeInformations.filter(information => information.uuid !== uuid);

                    }
                });
        },

        async editInformation(message, endDate, uuid) {
            const notificationStore = NotificationStores();

            const data = {
                message: message,
                endDate: endDate ?? undefined
            };

            xhr.addRequestToQueue("PUT", "/informations/" + uuid, data, true, true, false,
                (response) => {
                    if(response.code !== 200) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringEditInformation"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        const info = this.informations.find((information) => information.uuid === uuid);
                        info.message = message;
                        info.endDate = endDate;

                        const infoActive = this.activeInformations.find((information) => information.uuid === uuid);

                        if(infoActive) {
                            infoActive.message = message;
                            infoActive.endDate = endDate;
                        }

                        notificationStore.addNotification(t("informationEdited"), 'success');
                    }
                });
        },

        async createInformation(message, endDate) {
            const notificationStore = NotificationStores();

            const data = { message: message, endDate: endDate ?? undefined };

            xhr.addRequestToQueue("POST", "/informations", data, true, true, false,
                (response) => {
                    if(response.code !== 201) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringCreateInformation"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        const informationActive = JSON.parse(response.response);
                        informationActive.show = true;

                        this.informations.push(JSON.parse(response.response));
                        this.activeInformations.push(informationActive);

                        notificationStore.addNotification(t("informationCreate"), 'success');
                    }
                });
        },
    },
    getters : {}
});
