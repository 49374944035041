import { defineStore } from 'pinia';
import {NotificationStores} from "@/stores/NotificationStores";
import xhr from "@/utils/xhr";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";
import {CommandeStores} from "@/stores/CommandeStores";

export const FailedStore = defineStore("FailedStore", {
    state: () => ({
    }),
    actions: {
        create: async (poid, temp, uuidBobine, uuidImprimante, cause, uuidProduit, onSuccess) => {
            const notificationStore = NotificationStores();
            const commandeStore = CommandeStores();

            const data = {poid, temp, uuidBobine, uuidImprimante, cause}

            xhr.addRequestToQueue("POST", "/produits/" + uuidProduit + "/failed", data, true, true, false,
                (response) => {
                    if(response.code !== 200) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringCreatFailed"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {

                        const failed = JSON.parse(response.response);

                        const cmd = commandeStore.commandeSelected;
                        const produit = cmd.produits.find((produit) => produit.uuid = failed.uuidProduit);

                        produit.failed.push(failed)

                        onSuccess();

                        notificationStore.addNotification(t("failedCreated"), 'success');
                    }
                });

        }
    },
    getters : {}
});
