<template>
  <div class="w-[80vw] md:w-[40vw] lg:w-[20vw] bg-white-bg pt-2 text-center rounded-lg mt-4 mr-4 drop-shadow-xl z-50">
    <div class="flex flex-row items-center">
      <div class="w-[90%] pl-4">
        <span>{{ props.message }}</span>
      </div>
      <font-awesome-icon @click="closeNotification" icon="fas fa-times" class="cursor-pointer"/>
    </div>

    <div class="w-full rounded-full h-1 mt-2">
      <div class="h-1 rounded-full" :class="{'bg-red-600': props.type === 'error', 'bg-green-600': props.type === 'success'}" :style="`width: ${percent}%`"></div>
    </div>
  </div>
</template>

<script setup>

import {defineProps, ref} from "vue";
import {NotificationStores} from "@/stores/NotificationStores";

const notificationStore = NotificationStores();

const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  type: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: true
  }
});

let percent = ref(100);

const closeNotification = () => {
  notificationStore.removeNotification(props.id);
  clearInterval(durationIntervale);
};

const durationIntervale = setInterval(() => {
  percent.value -= 1;

  if(percent.value === 0) {
    closeNotification();
  }

}, 50);

</script>

<style scoped>

</style>