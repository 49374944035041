import {defineStore} from 'pinia'
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import {enumeration} from "../../Enumeration";
import {jsonValidator} from "../utils/JsonValidator";
import {t} from "@/utils/Traduction";

const consommationImprimante = 600;
const coef = 1.25;

export const CommandeStores = defineStore("CommandesStores", {
    state: () => ({
        commandes: [],
        commandesLoading: true,
        commandesOffset: 0,
        hasMore: true,
        commandeSelected: null,
        unpayedCommandes: [],
        unpayedAmount: 0
    }),
    actions: {
        async loadUnpayed() {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", "/commandes/unpayed", undefined, true, true, false,
                (response) => {
                    if(response.code !== 200) {

                        if(response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringGetOrders"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        const body = JSON.parse(response.response);

                        this.unpayedAmount = body.prixTotal;
                        this.unpayedCommandes = body.commandes;
                    }
                });
        },
        async getCommandeFromOffsetAdmin(offset, uuidUserFilter, uuidEtatFilter) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", `/commandes/${offset}/all`, {uuidUserFilter, uuidEtatFilter}, true, true, false,
            (response) => {
                if(response.code !== 200) {

                    if(response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringGetOrders"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    const newCommandes = JSON.parse(response.response);
                    this.commandes = this.commandes.concat(newCommandes);

                    if(newCommandes.length < 20) {
                        this.hasMore = false;
                    }

                    this.commandesLoading = false;
                }
            });
        },

        async getCommandeFromOffset() {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", `/commandes/${this.commandesOffset}`, undefined, true, true, false,
            (response) => {
                if (response.code !== 200) {

                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringGetOrders"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    const newCommandes = JSON.parse(response.response);
                    this.commandes = this.commandes.concat(newCommandes);

                    this.commandesOffset += 20;

                    if (newCommandes.length < 20) {
                        this.hasMore = false;
                    }

                    this.commandesLoading = false;
                }
            });
        },

        async getCommandeById(uuid) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", `/commandes/${uuid}/detail`, undefined, true, true, false,
            (response) => {
                if(response.code !== 200) {

                    if(response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringGetOrder"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }

                    return null;
                } else {
                    this.commandeSelected = JSON.parse(response.response);
                }
            });
        },

        resetSelected() {
            this.commandeSelected = {notLoad: true, id:"", nom: "", etat: {id: "", libelle: "", notLoad: true}, produits: [], dateDebut: "", utilisateur: {email: "", livraison: ""}, cout: {total: "", production: ""}, PrixBobine: "", PrixElectricite: ""};

            for (let i = 0; i < 4; i++) {
                this.commandeSelected.produits.push({notLoad: true, nom: "", nombre: "", poid: "", temp: "", couleur: {libelle: ""}, etat: {notLoad: true, libelle: ""}, PrixBobine: "", PrixElectricite: ""});
            }
        },

        updateProduitInCommandeSelected(produit) {
            let indexProduit = -1;
            for(let i = 0; i < this.commandeSelected.produits.length; i++) {
                if(this.commandeSelected.produits[i].uuid === produit.uuid) {
                    indexProduit = i;
                    break;
                }
            }

            if(indexProduit === -1) {
                const notificationStore = NotificationStores();
                notificationStore.addNotification(
                    t("pleaseRefreshPage"),
                    "error");
            } else {
                this.$state.commandeSelected.produits[indexProduit] = produit;
            }

            let allProduitIsDone = true;
            let oneIsWipOrDone = false;
            let allProduitIsCancel = true;

            let nbGramme = 0;
            let nbHeure = 0;
            let nbPiece = 0;

            for(let i = 0; i < this.commandeSelected.produits.length; i++) {
                if(this.commandeSelected.produits[i].etat.libelle !== enumeration.etatObjet.IMPRIMEE && this.commandeSelected.produits[i].etat.libelle !== enumeration.etatObjet.ANNULE) {
                    allProduitIsDone = false;
                }

                if(this.commandeSelected.produits[i].etat.libelle !== enumeration.etatObjet.ANNULE) {
                    allProduitIsCancel = false;
                }

                if(this.commandeSelected.produits[i].etat.libelle === enumeration.etatObjet.IMPRIMEE
                    || this.commandeSelected.produits[i].etat.libelle === enumeration.etatObjet.EN_IMPRESSION) {
                    oneIsWipOrDone = true;
                }

                if(this.commandeSelected.produits[i].etat.libelle !== enumeration.etatObjet.ANNULE) {
                    nbGramme += (this.commandeSelected.produits[i].poid * this.commandeSelected.produits[i].nombre);
                    nbHeure += (this.commandeSelected.produits[i].temp * this.commandeSelected.produits[i].nombre);
                    nbPiece += this.commandeSelected.produits[i].nombre;
                }
            }

            const indexCommandeInCommandes = this.commandes.findIndex((commande) => commande.uuid === this.commandeSelected.uuid);
            if(allProduitIsCancel) {
                this.$state.commandeSelected.etat.libelle = enumeration.etatCommande.ANNULEE;

                if(indexCommandeInCommandes !== -1) {
                    this.$state.commandes[indexCommandeInCommandes].etat.libelle = enumeration.etatCommande.ANNULEE;
                }
            } else if(allProduitIsDone) {
                this.$state.commandeSelected.etat.libelle = enumeration.etatCommande.TERMINEE;

                if(indexCommandeInCommandes !== -1) {
                    this.$state.commandes[indexCommandeInCommandes].etat.libelle = enumeration.etatCommande.TERMINEE;
                }

            } else if(oneIsWipOrDone) {
                this.$state.commandeSelected.etat.libelle = enumeration.etatCommande.EN_COURS;

                if(indexCommandeInCommandes !== -1) {
                    this.$state.commandes[indexCommandeInCommandes].etat.libelle = enumeration.etatCommande.EN_COURS;
                }
            }

            const poidsBobineEnKilo = nbGramme / 1000;
            const prixBobineTotal = poidsBobineEnKilo * this.$state.commandeSelected.PrixBobine
            const nbMinuteEnHeure = nbHeure / 60;
            const nbKWHUtilise = (consommationImprimante * nbMinuteEnHeure) / 1000;
            const prixKWHTotal = nbKWHUtilise * this.$state.commandeSelected.PrixElectricite;
            const coutTotalProduction = prixKWHTotal + prixBobineTotal;
            const coutTotal = (coutTotalProduction + nbPiece) * coef;

            this.$state.commandeSelected.cout.production =  Math.round(coutTotalProduction * 100) / 100;
            this.$state.commandeSelected.cout.total =  Math.round(coutTotal * 100) / 100;
        },

        updateCommandeStatusBySelected(status) {
            const indexCommandeInCommandes = this.commandes.findIndex((commande) => commande.uuid === this.commandeSelected.uuid);

            if(indexCommandeInCommandes !== -1) {
                this.commandes[indexCommandeInCommandes].etat.libelle = status;
            }

            this.commandeSelected.etat.libelle = status;
        }
    },
    getters : {}
});
