<template>
  <div v-if="utilisateurStore.role === enumeration.role.ADMIN" class="w-full md:w-auto px-8 md:px-0 md:mx-32">
    <h1 class="text-2xl font-bold mt-8">{{t("versionManagement")}}</h1>

    <div class="flex flex-col w-full lg:mx-8">
      <select
          class="mt-4"
        v-model="typeNouvelleVersion">
        <option :value="enumeration.typeVersion.MAJEURE">{{t("major")}}</option>
        <option :value="enumeration.typeVersion.MINEURE">{{t("minor")}}</option>
        <option :value="enumeration.typeVersion.FIX" selected>{{t("fix")}}</option>
      </select>
      <textarea class="h-32 mt-4 pt-2 pl-2"
            v-model="nouvelleVersion"
            :placeholder="t('versionDescription')"></textarea>
    </div>

    <div class="flex w-full justify-end mr-8 md:mr-0 md:ml-8 mt-4">
      <button v-on:click="sendNewVersion"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">{{t("addVersion")}}</button>
    </div>
  </div>

  <IdCardVersion v-for="version of versionStore.versions" :key="version"
                 :version="version"/>

</template>

<script setup>

import {VersionStore} from "@/stores/VersionStore";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration";
import {NotificationStores} from "@/stores/NotificationStores";
import {ref} from "vue";
import xhr from "@/utils/xhr";
import IdCardVersion from "@/components/idCardVersion.vue";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "../utils/Traduction";

const versionStore = new VersionStore();
const utilisateurStore = new UtilisateurStores();
const notificationStore = new NotificationStores();

if(versionStore.versions.length === 0) {
  versionStore.loadVersions();
}

const typeNouvelleVersion = ref(enumeration.typeVersion.FIX);
const nouvelleVersion = ref("");

const sendNewVersion = async () => {
  if(nouvelleVersion.value === "") {
    notificationStore.addNotification(
        t("needFillDescriptionForThisVersion"),
        "error");
  }

  xhr.addRequestToQueue("POST", "/versions", {description: nouvelleVersion.value,
    versionType: typeNouvelleVersion.value}, true, true, false, (response) => {
    if (response.code !== 201) {
      if (response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringCreateVersion"),
            "error");
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
      }
    } else {
      notificationStore.addNotification(
          t("versionCreated"),
          "success");

      versionStore.addVersion(JSON.parse(response.response));
      nouvelleVersion.value = "";
    }
  });
}

</script>

<style scoped>
</style>